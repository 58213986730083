import React, { Component } from "react"
import { graphql } from "gatsby"
import Layout from "../layouts"
import { Helmet } from "react-helmet"
import HeroImage from "../components/heroimage"
import { Flex, Box } from "@rebass/grid/emotion" //https://github.com/rebassjs/grid
import { css } from "@emotion/core" // https://github.com/gatsbyjs/gatsby/blob/master/examples/using-emotion/src/pages/index.js
import {
  PageHeader,
  PaddedMobile,
  GreenButton,
  HeroContainer,
  HeroLinks,
  HeroTextOverlay,
  HeroTextOverlayInner,
} from "../utils/styles"

class About extends Component {
  render() {
    const data = this.props.data
    return (
      <Layout>
        <Helmet
          title={`MA User Experience Design | About`}
          meta={[{ name: "description", content: "about" }]}
        />

        <HeroContainer>
          {data.wordpressPage.featured_media &&
            data.wordpressPage.featured_media.localFile && (
              <HeroImage
                image={
                  data.wordpressPage.featured_media.localFile.childImageSharp
                    .fluid
                }
              />
            )}
          <HeroTextOverlay>
            <HeroTextOverlayInner>
              <div css={[HeroLinks, PageHeader]}>
                <div
                  css={[
                    GreenButton,
                    css`
                      margin-bottom: 15px;
                    `,
                  ]}
                >
                  <a href="https://www.arts.ac.uk/subjects/animation-interactive-film-and-sound/postgraduate/ma-user-experience-design-lcc#apply">
                    Apply
                  </a>
                </div>
                <div css={GreenButton}>
                  <a href="mailto:j.fass@arts.ac.uk">Contact</a>
                </div>
              </div>
            </HeroTextOverlayInner>
          </HeroTextOverlay>
        </HeroContainer>

        <Flex flexWrap="wrap" flexDirection="row" css={[PaddedMobile]}>
          <Box width={[1, 8 / 12]} px={0} key={`post-images`}>
            <h1 css={PageHeader}>{data.wordpressPage.title}</h1>
            <div
              dangerouslySetInnerHTML={{ __html: data.wordpressPage.content }}
            />
          </Box>
        </Flex>
      </Layout>
    )
  }
}

export default About

// Set here the ID of the home page.
export const pageQuery = graphql`
  query {
    wordpressPage(wordpress_id: { eq: 5 }) {
      title
      content
      featured_media {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
